import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

class Thanks extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <section>
          <Helmet title="Thanks" />
          <h1>Thank You</h1>
          <p>Your message has been processed.</p>
          <h3 className="mt">
            <Link to="/">Visit the homepage</Link>
          </h3>
        </section>
      </Layout>
    )
  }
}

export default Thanks
